import { useState, useEffect, useRef } from "react";
//import logo from './logo.svg';
//import './App.css';
//import './index.css';
import MyModal from "../component/reserver/model";
//import { Page } from './pages/page';
import SliderComment from "../component/comment/comment";
import Filter from "../component/inputs/inputs.js";
import FilterLocation from "../component/inputs/inputsLocation";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import SliderCars from "../component/cars/cars";
import { data } from "../data";
registerLocale("fr", fr);

function PageFes() {
  const [selectedCars, setSelectedCars] = useState("Sélectionner véhicule");
  const [selectedLocation, setSelectedLocation] = useState(
    "Sélectionner location"
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [visible, setMenuMobileVisible] = useState(false);

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const [selectedCategory, setSelectedCategory] = useState("SUV");

  useEffect(() => {}, [
    selectedCategory,
    selectedCars,
    selectedLocation,
    startDate,
    endDate,
    visible,
  ]);

  const inputs = {
    selectedCars: selectedCars?.name,
    selectedLocation: selectedLocation?.name,
    startDate: startDate,
    endDate: endDate,
  };

  function dateHandler(params) {
    console.log(params);
    return "rrrrr";
  }

  const refNonVehicule = useRef(null);
  const refApropos = useRef(null);
  const refAvis = useRef(null);

  const scrollTo = (ref, event) => {
    setMenuMobileVisible(false);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="wrapper ovh fes">
        <div id="page" className="stylehome1 h0">
          <div className="mobile-menu">
            <div className="header stylehome1">
              <div className="mobile_menu_bar">
                <a
                  className="menubar"
                  onClick={() => setMenuMobileVisible(!visible)}
                >
                  <small>Menu</small>
                  <span></span>
                </a>
              </div>
              <div className="mobile_menu_main_logo">
                <img
                  className="nav_logo_img img-fluid w-full"
                  src="images/LOGO-CARABLANCA-bleu-horizontal.svg"
                  alt="images/header-logo2.png"
                />
              </div>
            </div>
          </div>
          <nav
            id="menu"
            className={`stylehome1  mm-menu mm-menu_offcanvas ${
              visible ? "open" : ""
            }`}
          >
            <div
              className="mm-menu-bg"
              onClick={() => setMenuMobileVisible(false)}
            ></div>
            <div class="mm-panels">
              <div
                id="mm-0"
                class="mm-panel mm-panel_has-navbar mm-panel_opened"
              >
                <div class="mm-navbar">
                  <a class="mm-navbar__title">Menu</a>
                </div>
                <ul class="mm-listview">
                  <li class="mm-listitem">
                    <span
                      class="mm-listitem__text"
                      onClick={scrollTo.bind(this, refApropos)}
                    >
                      <span>À propos de nous</span>
                    </span>
                  </li>
                  <li
                    class="mm-listitem"
                    onClick={scrollTo.bind(this, refNonVehicule)}
                  >
                    <span class="mm-listitem__text">
                      <span>Nos véhicules</span>
                    </span>
                  </li>
                  <li
                    class="mm-listitem"
                    onClick={scrollTo.bind(this, refAvis)}
                  >
                    <span class="mm-listitem__text">
                      <span>Avis Clients</span>{" "}
                    </span>
                  </li>
                  <li class="mm-add-listing mm-listitem">
                    <span class="border-none mm-listitem__text">
                      <span class="mmenu-contact-info">
                        <span className="phone-num">
                          <i className="flaticon-map"></i>{" "}
                          <span style={{ "white-space": "normal" }}>
                            {data?.headerDonneés?.Adress}
                          </span>
                        </span>
                        <span className="phone-num">
                          <i className="flaticon-phone-call"></i>{" "}
                          <a href={`tel:${data?.headerDonneés?.phone}`}>
                            {data?.headerDonneés?.phone}
                          </a>
                        </span>
                      </span>
                      <span class="social-links">
                        <a
                          href={data?.headerDonneés?.faceBook}
                          target="_blank"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="fab fa-facebook-f"></span>
                        </a>
                        {/* <a href={data?.headerDonneés?.twitter} target="_blank" target="_blank"  rel="noreferrer">
                          <span className="fab fa-twitter"></span>
                        </a> */}
                        <a
                          href={data?.headerDonneés?.instagram}
                          target="_blank"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="fab fa-instagram"></span>
                        </a>
                        {/* <a href={data?.headerDonneés?.linkedIn} target="_blank" target="_blank"  rel="noreferrer">
                          <span className="fab fa-youtube"></span>
                        </a> */}
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <section className="home-one bg-home1">
          <div className="z-[100]">
            <div className="listing_sidebar z-[1]">
              <div className="siderbar_left_home pt20">
                <a
                  className="sidebar_switch sidebar_close_btn float-end"
                  href="#"
                >
                  X
                </a>
                <div className="footer_contact_widget mt100">
                  <span className="title">
                    {data?.sideBar?.contactInfo?.title}
                  </span>
                  <p>{data?.sideBar?.contactInfo?.paragraph}</p>
                </div>
                <div className="footer_contact_widget">
                  <span className="title">CONTACT</span>
                  <div className="footer_phone">
                    {data?.sideBar?.contactInfo?.tel}
                  </div>
                  <p>{data?.sideBar?.contactInfo?.email}</p>
                </div>
                <div className="footer_about_widget">
                  <span className="title">OFFICE</span>
                  <p>{data?.sideBar?.contactInfo?.adress}</p>
                </div>
                <div className="footer_contact_widget">
                  <span className="title">OPENING HOURS</span>
                  <p>{data?.sideBar?.contactInfo?.openningHours}</p>
                </div>
              </div>
            </div>
            <div className="header_top dn-992 z-[1]">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-xl-7">
                    <div className="header_top_contact_opening_widget text-center text-md-start">
                      <ul className="mb0">
                        <li className="list-inline-item">
                          <a href={`tel:${data?.headerDonneés?.phone}`}>
                            <span className="flaticon-phone-call"></span>
                            {data?.headerDonneés?.phone}
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a>
                            <span className="flaticon-map"></span>
                            {data?.headerDonneés?.Adress}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-5">
                    <div className="header_top_social_widgets text-center text-md-end">
                      <ul className="m0">
                        <li className="list-inline-item">
                          <a href={data?.headerDonneés?.faceBook}>
                            <span className="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        {/* <li className="list-inline-item">
                          <a href={data?.headerDonneés?.twitter}>
                            <span className="fab fa-twitter"></span>
                          </a>
                        </li> */}
                        <li className="list-inline-item">
                          <a href={data?.headerDonneés?.instagram}>
                            <span className="fab fa-instagram"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <header className="header-nav menu_style_home_one main-menu ">
              <nav>
                <div className="container posr">
                  <div className="menu-toggle">
                    <button type="button" id="menu-btn">
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <a
                    href="index.html"
                    className="navbar_brand float-start dn-md"
                  >
                    <img
                      className="logo2 img-fluid w-full"
                      src="images/LOGO-CARABLANCA-bleu-horizontal.svg"
                      alt="LOGO-CARABLANCA-bleu-horizontal.svg"
                    />
                  </a>

                  <ul
                    id="respMenu"
                    className="ace-responsive-menu text-end"
                    data-menu-style="horizontal"
                  >
                    <li>
                      {" "}
                      <a
                        className="cursor-pointer"
                        onClick={scrollTo.bind(this, refApropos)}
                      >
                        <span className="title">À propos de nous</span>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        className="cursor-pointer"
                        onClick={scrollTo.bind(this, refNonVehicule)}
                      >
                        <span className="title">Nos véhicules</span>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        className="cursor-pointer"
                        onClick={scrollTo.bind(this, refAvis)}
                      >
                        <span className="title">Avis Clients</span>
                      </a>
                    </li>
                    <li className="sidebar_panel">
                      <a className="sidebar_switch pt0" href="#">
                        <span></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          </div>
          <div className="container">
            <div className="row posr">
              <div className="col-lg-16 m-auto">
                <div className="home_content home1_style">
                  <div className="home-text text-center mb30">
                    <h1 className="title">
                      <span className="aminated-object1">
                        <img
                          className="objects"
                          src="images/home/title-bottom-border.svg"
                          alt={data?.firstContainer?.title}
                        />
                      </span>
                      {data?.firstContainer?.title} Fes
                    </h1>
                    <p className="para">{data?.firstContainer?.title}</p>
                  </div>
                  <div className="max-w-1160 mx-auto">
                    <div className="mb0 text-center grid grid-cols-12 gap-y-3 gap-x-0 md:gap-x-14">
                      <div className="col-span-12 md:col-span-6">
                        <div className="input-filter-box">
                          <Filter
                            people={data?.firstContainer?.cars}
                            selected={selectedCars}
                            setSelected={setSelectedCars}
                            labelText={data?.firstContainer?.véhiculeInput}
                          />
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <div className={`input-filter-box`}>
                          <div className="active">
                            <i className="icon fas fa-calendar-week"></i>
                            <label className="title text-left font-bold ">
                              {data?.firstContainer?.dateReservation}
                            </label>
                            <DatePicker
                              locale="fr"
                              className="relative shadow-md rounded-md w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                              customInput={<input color="success" />}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              dateFormat="dd/MM/yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <div className="input-filter-box">
                          <div className="active">
                            <i className="icon fas fa-calendar-week"></i>
                            <label className="title text-left font-bold">
                              {data?.firstContainer?.dateRestitution}
                            </label>
                            <DatePicker
                              locale="fr"
                              className="relative shadow-md rounded-md w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                              customInput={
                                <input
                                  color="success"
                                  label={"la date de restitustion"}
                                />
                              }
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              dateFormat="dd/MM/yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <div className="input-filter-box">
                          <FilterLocation
                            people={data?.firstContainer?.point}
                            selected={selectedLocation}
                            setSelected={setSelectedLocation}
                            labelText={data?.firstContainer?.locationInput}
                            icon="fas fa-map-marker-alt"
                          />
                        </div>
                      </div>
                      <div className="col-span-12 mb0 text-right mt-2">
                        <button
                          type="button"
                          onClick={openModal}
                          className="h-16 w-full lg:w-56 text-white font-bold py-2 px-4 rounded-full text-xl btn-booking"
                        >
                          <span>{data?.firstContainer?.button}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MyModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            inputs={inputs}
            portalId={data?.portalId}
            formGuid={data?.formGuid}
            url={data?.url}
          />
        </section>

        {/* <div className="container mt-72 md:mt-2 md:my-2 px-6 mx-auto ">
          <section id="CommentCaMarche" className="text-gray-800">
            <div className="flex flex-wrap mb-12">
              <div className="grow-0 shrink-0 basis-auto w-full lg:w-6/12">
                <h3 className="text-2xl font-bold  mb-4">
                  {data?.secondContainer?.title}
                </h3>
                <p className="text-gray-500">{data?.secondContainer?.paragraph}</p>
              </div>
              <div className="grow-0 shrink-0 basis-auto w-full lg:w-6/12 lg:pl-6 mb-6 lg:mb-0">
                <div
                  className="relative overflow-hidden bg-no-repeat bg-cover ripple shadow-lg rounded-lg"
                  style={{ backgroundPosition: "50%" }}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src={data?.secondContainer?.image}
                    className="w-full"
                    alt="Louvre"
                  />
                  <a href="#!">
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.2)" }}
                    ></div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div> */}

        <section
          ref={refNonVehicule}
          id="NousVehicule"
          className="featured-product"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="main-title text-left text-center">
                  <h2>{data?.thirdContainer?.title}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="popular_listing_sliders row">
                  <div
                    className="nav nav-tabs col-lg-12 justify-content-center"
                    role="tablist"
                  >
                    <button
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={(e) => {
                        setSelectedCategory(
                          data?.thirdContainer?.carsCategory[0]
                        );
                      }}
                    >
                      {data?.thirdContainer?.carsCategory[0]}
                    </button>
                    <button
                      className="nav-link"
                      id="nav-shopping-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-shopping"
                      role="tab"
                      aria-controls="nav-shopping"
                      aria-selected="false"
                      onClick={(e) =>
                        setSelectedCategory(
                          data?.thirdContainer?.carsCategory[1]
                        )
                      }
                    >
                      {data?.thirdContainer?.carsCategory[1]}
                    </button>
                    <button
                      className="nav-link"
                      id="nav-hotels-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-hotels"
                      role="tab"
                      aria-controls="nav-hotels"
                      aria-selected="false"
                      onClick={(e) =>
                        setSelectedCategory(
                          data?.thirdContainer?.carsCategory[2]
                        )
                      }
                    >
                      {data?.thirdContainer?.carsCategory[2]}
                    </button>
                  </div>

                  <div className="tab-content col-lg-12" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <SliderCars
                        cars={data?.thirdContainer?.cardSliderCars}
                        selectedCategory={selectedCategory}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div ref={refAvis} id="AvisClient" className="bg-slate-100 py-12">
          <div className="container px-3 mx-auto ">
            <div class="main-title text-left text-center">
              <h2>Avis Clients </h2>
            </div>
            <div className="grid grid-cols-12 gap-x-0 md:gap-x-10 gap-y-10 md:gap-y-0">
              <div className="col-span-12 lg:col-span-6">
                <div className="bg-white rounded-xl p-4">
                  <h3 className="text-xl mb-4">
                    Réservez sur notre site internet plutôt que sur un
                    comparateur
                  </h3>
                  <ul className="mb-0 list-unstyled">
                    <li className="my-3">
                      <div className="d-flex">
                        <div>
                          <span className="icon-check">
                            <i className="far fa-check"></i>
                          </span>
                        </div>
                        <div className="ml-2 text-base">
                          Évitez les surprises sur les assurances vendues par
                          des tiers
                        </div>
                      </div>
                    </li>
                    <li className="my-3">
                      <div className="d-flex">
                        <div>
                          <span className="icon-check">
                            <i className="far fa-check"></i>
                          </span>
                        </div>
                        <div className="ml-2 text-base">
                          Pas de frais supplémentaires, prix final garanti
                        </div>
                      </div>
                    </li>
                    <li className="my-3">
                      <div className="d-flex">
                        <div>
                          <span className="icon-check">
                            <i className="far fa-check"></i>
                          </span>
                        </div>
                        <div className="ml-2 text-base">
                          Meilleur prix garanti
                        </div>
                      </div>
                    </li>
                    <li className="my-3">
                      <div className="d-flex">
                        <div>
                          <span className="icon-check">
                            <i className="far fa-check"></i>
                          </span>
                        </div>
                        <div className="ml-2 text-base">
                          Aucune dépôt, aucune franchise
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="bg-white rounded-xl p-4 h-full">
                  <h3 className="text-xl mb-4">
                    Nos clients ont confiance en la qualité des services que
                    nous leur offrons
                  </h3>
                  <div className="text-base">
                    Carablanca reconnaît que la satisfaction du client est la
                    clé de leur réussite dans le secteur de la location de
                    voitures, notre personnel de Carablanca s'efforce de fournir
                    un service exceptionnel à chaque client pour garantir leur
                    satisfaction à chaque étape du processus de location.
                  </div>
                  <div className="flex justify-center text-3xl color-yellow mt-8">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star-half-alt"></i>
                    <i className="far fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div id="AvisClient" className=" text-gray-800 mt-16">
              <div className="container mx-auto  text-left">
                <h2 className="text-xl lg:text-3xl font-normal mb-6 text-center mb-6">
                  {data?.fourthContainer?.title}
                </h2>
                <SliderComment comment={data?.fourthContainer?.comment} />
              </div>
            </div>
          </div>
        </div>

        <div
          ref={refApropos}
          className="container md:mt-2 md:my-2 px-6 mx-auto "
        >
          <section id="àproposdeNous" className="text-gray-800">
            <div className="flex flex-wrap mb-12">
              <div className="grow-0 shrink-0 basis-auto w-full">
                <h2 className="text-2xl lg:text-4xl font-bold mb-2 lg:mb-4">
                  {data?.fifthContainer?.title}
                </h2>
                <div
                  className="text-gray-500 text-sm lg:text-md"
                  dangerouslySetInnerHTML={{
                    __html: data?.fifthContainer?.paragraph,
                  }}
                ></div>
              </div>
            </div>
          </section>
        </div>

        <section className="footer_one pt50 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-xl-7">
                <div className="footer_about_widget flex justify-center lg:justify-start">
                  <div className="logo mb40 mb0-sm">
                    <img
                      src="images/LOGO-CARABLANCA-blanc.png"
                      alt="header-logo.png"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-xl-5">
              <div className="footer_menu_widget text-start text-md-end mt15">
                  <ul className="flex flex-wrap justify-center lg:justify-end">
                    <li className="list-inline-item mb-3">
                      <span onClick={scrollTo.bind(this, refApropos)} className="cursor-pointer">
                        À propos de nous
                      </span>
                    </li>
                    <li className="list-inline-item mb-3">
                      <span onClick={scrollTo.bind(this, refNonVehicule)} className="cursor-pointer">
                        Nos véhicules
                      </span>
                    </li>
                    <li className="list-inline-item mb-3">
                      <span onClick={scrollTo.bind(this, refAvis)} className="cursor-pointer">Avis clients</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="container  pb25 pt25">
            <div className="row">
              <div className="col-md-6 col-lg-8 col-xl-9">
                <div className="copyright-widget mt5 text-start mb20-sm  flex flex-wrap justify-center lg:justify-start">
                  <p>Carablanca © 2023. Tous droits réservés.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-xl-3">
                <div className="footer_social_widget text-start text-md-end flex flex-wrap justify-center lg:justify-end">
                  <ul className="mb0">
                    <li className="list-inline-item">
                      <a
                        href={data?.headerDonneés?.faceBook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li className="list-inline-item">
                      <a
                        href={data?.headerDonneés?.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <a className="scrollToHome" href="#">
          <i className="fas fa-arrow-up"></i>
        </a>
      </div>
    </div>
  );
}

export default PageFes;
