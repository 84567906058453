import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
//import { Client } from "@hubspot/api-client";
import axios from "axios";
import { useForm } from "react-hook-form";

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export default function MyModal({
  isOpen,
  setIsOpen,
  inputs,
  formGuid,
  url,
  portalId,
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();


  const [name, setName] = useState();
  const [secondName, setSecondName] = useState();
  const [email, setEmail] = useState();
  const [tel, setTel] = useState();
  //const hubspotClient = new Client({ accessToken: "pat-eu1-32720ed0-14ce-4bdf-be5a-f4d666393060" });

  const submit_hubspot_form = async () => {
    // const portalId = '26331437'; // example portal ID (not real)
    // const formGuid = '4806cbf4-bfea-40c6-b3a8-1c7bf04e3714'; // example form GUID (not real)
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${url}/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,

        fields: [
          {
            name: "firstname",
            value: name,
          },
          {
            name: "lastname",
            value: secondName,
          },
          {
            name: "email",
            value: email === "" || email === undefined ? "not Selected" : email,
          },
          {
            name: "phone",
            value: tel,
          },
          {
            name: "creation",
            value: inputs?.startDate,
          },
          {
            name: "car",
            value:
              inputs?.selectedCars === "" || inputs?.selectedCars === undefined
                ? "not Selected"
                : inputs?.selectedCars,
          },
          {
            name: "pointretrait",
            value:
              inputs?.selectedLocation === "" ||
                inputs?.selectedLocation === undefined
                ? "not Selected"
                : inputs?.selectedLocation,
          },
          {
            name: "restitution",
            value: inputs?.endDate,
          },
        ],
      },
      config
    );
    if (response.request.status === 200) {
      setIsOpen1(true);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'event': 'confirmation-lead' });
    } else {
      setIsOpen2(true);
      console.log(response);
    }
    console.log(response.request.status);
    return response;
  };

  function closeModal() {
    setIsOpen(false);
  }

  async function Confirmer() {
    closeModal();
    submit_hubspot_form();
  }

  //Conirmation Panel
  let [isOpen1, setIsOpen1] = useState(false);

  function closeModal1() {
    setIsOpen1(false);
  }

  const [isOpen2, setIsOpen2] = useState(false);
  function closeModal2() {
    setIsOpen2(false);
    setIsOpen1(true);
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full md:max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6 md:mx-3">
                      <div className="w-full  px-3 mb-6 md:mb-2">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
                          htmlFor="grid-first-name"
                          required
                        >
                          Nom{" "}
                          <span className=" text-red-700 text-xs mb-2">
                            {" "}
                            *{" "}
                          </span>
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-first-name"
                          type="text"
                          placeholder="Nom"
                          value={name}
                          onInput={(e) => setName(e.target.value)}
                          {...register("firstName", { required: true })}
                        />
                        {errors.firstName && (
                          <p className="text-red-700 text-xs">
                            Ce champ est requis.
                          </p>
                        )}
                      </div>
                      <div className="w-full  px-3 mb-6 md:mb-2">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                          htmlFor="grid-last-name"
                        >
                          <p>
                            Prenom{" "}
                            <span className=" text-red-700 text-xs mb-2">
                              {" "}
                              *{" "}
                            </span>
                          </p>
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Prenom"
                          value={secondName}
                          onInput={(e) => setSecondName(e.target.value)}
                          {...register("lastName", { required: true })}
                        />
                        {errors.lastName && (
                          <p className="text-red-700 text-xs">
                            Ce champ est requis.
                          </p>
                        )}
                      </div>
                      <div className="w-full  px-3 mb-6 md:mb-2">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                          htmlFor="grid-last-name"
                        >
                          Email
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="email"
                          placeholder="Email"
                          value={email}
                          onInput={(e) => setEmail(e.target.value)}
                          {...register("email", {
                            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                          })}
                        />
                        {errors.email && (
                          <p className="text-red-700 text-xs">
                            Ce champ doit être l'adresse e-mail
                          </p>
                        )}
                      </div>
                      <div className="w-full  px-3 mb-6 md:mb-2">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                          htmlFor="grid-last-name"
                        >
                          Tél <span className=" text-red-700 text-xs mb-2">*</span>
                        </label>
                        <PhoneInput
                          {...register("phone", { pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g })}
                          defaultCountry={"MA"}
                          value={tel}
                          international
                          countryCallingCodeEditable={false}
                          onChange={(phone) => {
                            setTel(phone)
                          }}
                          placeholder="TÉL"
                          className="phone-input-costum bg-gray-200 text-gray-700 px-4 border border-gray-200 rounded"
                        />
                        {errors.phone && (
                          <p className="text-red-700 text-xs">
                            saisissez un numéro de téléphone valide.
                          </p>
                        )}
                      </div>
                    </div>
                  </form>

                  <div className="mt-4 px-4 flex justify-center">
                    <button
                      type="submit"
                      className="h-14 w-full lg:w-56 text-white font-bold py-2 px-4 rounded-full text-xl btn-booking"
                      onClick={handleSubmit(Confirmer)}
                    >
                      <span>Confirmer</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Félicitation!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Un Conseiller vous contacter sous 48H ,Pour confirmer
                      votre réservation
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="h-14 w-full lg:w-56 text-white font-bold py-2 px-4 rounded-full text-xl btn-booking"
                      onClick={closeModal1}
                    >
                      <span>Fermer</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Erreur!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Essayer de bien remplir les champs Nom ,Prénom et Tél .
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="h-14 w-full lg:w-56 text-white font-bold py-2 px-4 rounded-full text-xl btn-booking"
                      onClick={closeModal2}
                    >
                      <span>Fermer</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
