import Page from "./page/page";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageRabat from "./page/rabat";
import PageAgadir from "./page/agadir";
import PageFes from "./page/fes";
import PageMarrakesh from "./page/marrakech";
import PageTanger from "./page/tanger";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Page />} />

        <Route path="rabat" element={<PageRabat />} />
        <Route path="agadir" element={<PageAgadir />} />
        <Route path="fes" element={<PageFes />} />
        <Route path="marrakesh" element={<PageMarrakesh />} />
        <Route path="tanger" element={<PageTanger />} />

        <Route path="*" element={<Page />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
