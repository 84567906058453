export const data = {
    portalId: '26538052',
    formGuid: '796a8cf1-b9a4-470d-9dca-c41c602abfdd',
    url: "https://api.hsforms.com/submissions/v3/integration/submit",
    headerDonneés: {
        phone: "+212 661 317 145",
        Adress: "RES PANORAMA GH1 IMM 1 ETAGE 03 OFFICE 12, Casablanca 20000",
        faceBook: "https://www.facebook.com/profile.php?id=100072172661359",
        twitter: "",
        instagram: "https://instagram.com/carablanca.traveling?utm_medium=copy_link",
        linkedIn: "",

    },
    firstContainer: {
        title: "Location Voiture",
        caption: "Location Voiture",
        véhiculeInput: "Sélectionner un véhicule",
        cars: [{
                id: 7,
                name: 'Touareg VW'
            },
            {
                id: 8,
                name: 'Range rover sport'
            },
            {
                id: 9,
                name: 'Range rover évoque'
            },
            {
                id: 10,
                name: 'Audi Q8'
            },
            {
                id: 11,
                name: 'Tucson Hyundai'
            },
            {
                id: 12,
                name: 'Clio 5'
            },
            {
                id: 13,
                name: 'P 208'
            },
            {
                id: 14,
                name: 'Accent'
            },
            {
                id: 15,
                name: 'C3'
            },
            {
                id: 16,
                name: 'Fiat 500'
            },
            {
                id: 17,
                name: 'Jeep renegad'
            },
            {
                id: 18,
                name: 'Hyundai sanatafee'
            },
            {
                id: 19,
                name: 'Mercedes classe A'
            },
            {
                id: 20,
                name: 'BMW serie 05'
            },
            {
                id: 21,
                name: 'Golf 8'
            },
            {
                id: 22,
                name: 'Volkswagen T-roc'
            },
        ],
        dateReservation: "Date et heure de Réservation",
        dateRestitution: "Date et heure de restitution",
        locationInput: "Point de retrait",
        point: [{
                id: 3,
                name: 'Rabat'
            },
            {
                id: 4,
                name: 'Casablanca'
            },
            {
                id: 5,
                name: 'Marrakech'
            },
            {
                id: 6,
                name: 'Fes'
            },
            {
                id: 7,
                name: 'Agadir '
            },
            {
                id: 8,
                name: 'Tanger'
            },
        ],
        button: "Réserver"
    },
    secondContainer: {
        title: "Comment Ca marche!",
        paragraph: "Duis sagittis, turpis in ullamcorper venenatis, ligula nibh porta dui, sit amet rutrum enim massa in ante. Curabitur in justo at lorem laoreet ultricies. Nunc ligula felis, sagittis eget nisi vitae, sodales vestibulum purus. Vestibulum nibh ipsum, rhoncus vel sagittis nec, placerat vel justo. Duis faucibus sapien eget tortor finibus, a eleifend lectus dictum. Cras tempor convallis magna id rhoncus. Suspendisse potenti. Nam mattis faucibus imperdiet. Proin tempor lorem at neque tempus aliquet. Phasellus at e volutpat, varius arcu id, aliquam lectus. Vestibulum mattis felis quis ex pharetra luctus. Etiam luctus sagittis massa, sed iaculis est vehicula ut. Duis sagittis, turpis in ullamcorper venenatis, ligula nibh porta dui, sit amet rutrum enim massa in ante. Curabitur in justo at lorem laoreet ultricies. Nunc ligula felis, sagittis eget nisi vitae, sodales vestibulum purus. Vestibulum nibh ipsum, rhoncus vel sagittis nec, placerat vel justo. Duis faucibus sapien eget tortor finibus, a eleifend lectus dictum. Cras tempor convallis magna id rhoncus. Suspendisse potenti. Nam mattis faucibus imperdiet. Proin tempor lorem at neque tempus aliquet.",
        image: "https://ad962edbae8ba7b03b7f-d10007df79b5b7a4e475a291e50a08cf.ssl.cf3.rackcdn.com/ouvrir-agence-loc-voitures/ouvrir-une-agence-de-location-de-voiture.jpg",
    },
    thirdContainer: {
        title: "Choisissez la voiture de location qui vous convient le mieux",
        carsCategory: [
            "4x4", "SUV", "CITADINE"
        ],
        // cardSliderCars: [{
        //         price: "à partir de 300dh/jour",
        //         marque: "Renault - Clio 5 2020",
        //         type: "SUV",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "36 reviews",
        //         review: "4.8",
        //     },
        //     {
        //         price: "à partir de 300dh/jour",
        //         marque: "Renault - Clio 4 2020",
        //         type: "SUV",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "36 reviews",
        //         review: "4.8",
        //     },
        //     {
        //         price: "à partir de 300dh/jour",
        //         marque: "citroen-c-elysee",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "50 reviews",
        //         type: "SUV",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Volvo XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "4x4",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Volvo XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "4x4",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Range Rover XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "4x4",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Range Rover XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "4x4",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Pegeout 4008 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "4x4",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Volvo XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "CITADINE",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Volvo XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "CITADINE",
        //         review: "4.7",
        //     },
        //     {
        //         price: "à partir de 200dh/jour",
        //         marque: "Volvo XC90 - 2020",
        //         image: "https://images.caradisiac.com/logos-ref/modele/modele--renault-clio-5/S7-modele--renault-clio-5.jpg",
        //         numberofReviews: "684 reviews",
        //         type: "CITADINE",
        //         review: "4.7",
        //     },
        // ],
        cardSliderCars: [{
                price: "à partir de 300dh/jour",
                marque: "Touareg VW",
                type: "4x4",
                image: "images/cars/Touareg-VW.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "Range rover sport",
                type: "4x4",
                image: "images/cars/Range-rover-sport.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            }, {
                price: "à partir de 300dh/jour",
                marque: "Range rover évoque",
                type: "4x4",
                image: "images/cars/Range-rover-evoque.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            }, {
                price: "à partir de 300dh/jour",
                marque: "Audi Q8",
                type: "4x4",
                image: "images/cars/Audi-Q8.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            }, {
                price: "à partir de 300dh/jour",
                marque: "Tucson Hyundai",
                type: "4x4",
                image: "images/cars/Tucson-Hyundai.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },

            {
                price: "à partir de 300dh/jour",
                marque: "DUSTER",
                type: "SUV",
                image: "images/cars/DUSTER.png",
                numberofReviews: "36 reviews",
                review: "4.8",
            }, {
                price: "à partir de 300dh/jour",
                marque: "Jeep renegade",
                type: "SUV",
                image: "images/cars/Jeep-renegade.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            }, {
                price: "à partir de 300dh/jour",
                marque: "Hyundai sanatafee",
                type: "SUV",
                image: "images/cars/Hyundai-sanatafee.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            }, {
                price: "à partir de 300dh/jour",
                marque: "Volkswagen T-roc",
                type: "SUV",
                image: "images/cars/Volkswagen-T-roc.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },



            {
                price: "à partir de 300dh/jour",
                marque: "Clio 5",
                type: "CITADINE",
                image: "images/cars/Clio-5.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "Peugeot 208",
                type: "CITADINE",
                image: "images/cars/Peugeot-208.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },


            {
                price: "à partir de 300dh/jour",
                marque: "Hyundai Accent",
                type: "CITADINE",
                image: "images/cars/Hyundai-Accent.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "Citroën C3",
                type: "CITADINE",
                image: "images/cars/Citroen-C3.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "Fiat 500",
                type: "CITADINE",
                image: "images/cars/Fiat-500.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "Mercedes classe A",
                type: "CITADINE",
                image: "images/cars/Mercedes-classe-A.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "BMW serie 05",
                type: "CITADINE",
                image: "images/cars/BMW-serie-05.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
            {
                price: "à partir de 300dh/jour",
                marque: "Golf-8",
                type: "CITADINE",
                image: "images/cars/Golf-8.jpg",
                numberofReviews: "36 reviews",
                review: "4.8",
            },
        ],
    },
    fourthContainer: {
        title: "Nos avis clients",
        comment: [{
                user: "Inass",
                date: "14/10/2022",
                comment: " Je viens de louer une voiture avec Carablanca et je suis absolument ravi du service que j'ai reçu. Tout d'abord, la réservation en ligne a été simple et rapide. Ensuite, lorsque j'ai arrivé à l'agence, j'ai été accueilli par un personnel aimable et professionnel qui m'a aidé à faire le tour du véhicule et à finaliser les détails de la location.",
                period: "2 days ago",
            },
            {
                user: "Hicham",
                date: "15/10/2022",
                comment: "L'expérience de location de voiture avec Carablanca a été positive. La réservation en ligne a été facile et rapide, et le personnel à l'agence était amical et professionnel. La voiture était en excellent état et le tarif était compétitif. je recommande Carablanca pour un service de location de voiture de qualité à un prix abordable dans tous le Maroc.",
                period: "2 days ago",
            },
            {
                user: "Mohamed",
                date: "14/10/2022",
                comment: "Je suis extrêmement satisfait du service que j'ai reçu. Le personnel à l'agence était chaleureux et accueillant, et ils ont pris le temps de m'expliquer tous les détails de la location et de faire un tour complet de la voiture avec moi. je recommande vivement Carablanca",
                period: "2 days ago",
            },
        ]
    },
    fifthContainer: {
        title: "A propos de nous",
        paragraph: `<b>Carablanca</b> est une agence de location de voitures offrant ses services dans toutes les villes du Maroc. Nous sommes fiers de proposer une large gamme de véhicules pour répondre à tous les besoins de nos clients, que ce soit pour un voyage d'affaires ou une escapade en famille.
                    <br>Notre objectif est de fournir un service rapide, fiable et abordable à nos clients. Nous disposons d'une équipe dévouée et expérimentée pour garantir que chaque expérience de location soit aussi agréable que possible. 
                    <br>En choisissant Carablanca, vous bénéficiez de nombreux avantages tels que des tarifs compétitifs, une flotte régulièrement renouvelée et des options de location flexibles pour s'adapter à vos besoins. 
                    <br>N'hésitez pas à nous contacter pour en savoir plus sur nos services et trouver la solution de location de voitures qui convient le mieux à vos besoins au Maroc. Chez Carablanca, votre satisfaction est notre priorité absolue.`
    },
    sideBar: {
        contactInfo: {
            title: "Quick contact Info",
            paragraph: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis Theme natoque penatibus et magnis dis parturient montes, nascetur.",
            tel: "+212 612 12 12 12",
            email: "",
            adress: "47 yasmine, Casablanca, Maroc",
            openningHours: "Monday – Friday: 09:00AM – 09:00PM      - Saturday: 09:00AM – 07:00PM - Sunday: Closed",
        }
    }
}
