import React, { useState, useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";

// Import Swiper styles
import "swiper/css";

const Card = ({ car }) => {
  return (
    <div className="car-listing">
      <div className="thumb">
        <img src={car?.image} alt="1.jpg" className="image-car" />
        {/* <div className="thmb_cntnt2">
            <ul className="mb0">
              <li className="list-inline-item"><a className="text-white" href="#"><span className="flaticon-photo-camera mr3"></span> 22</a></li>
              <li className="list-inline-item"><a className="text-white" href="#"><span className="flaticon-play-button mr3"></span> 3</a></li>
            </ul>
          </div>
          <div className="thmb_cntnt3">
            <ul className="mb0">
              <li className="list-inline-item"><a href="#"><span className="flaticon-shuffle-arrows"></span></a></li>
              <li className="list-inline-item"><a href="#"><span className="flaticon-heart"></span></a></li>
            </ul>
          </div>
          */}
      </div>
      <div className="details">
        <div className="wrapper text-center">
          <h3 className="title">
            <a href="page-car-single-v1.html">{car?.marque}</a>
          </h3>
          {/* <p className="price text-sm">{car?.price}</p> */}
        </div>
      </div>
    </div>
  );
};

const SliderCars = ({ cars, selectedCategory }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      "(min-width: 200px)": {
        slides: { perView: 1 },
      },
      "(min-width: 900px)": {
        slides: { perView: 3, spacing: 20 },
      },
    },

    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <>
      {/* <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider max-w-1160 mx-auto">
          {cars
            ?.filter(function(item) {
              return item?.type === selectedCategory;
            })
            .map((car,index) => {
              return (
                <div className="keen-slider__slide " key={index}>
                  <Card car={car} />
                </div>
              );
            })}
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details?.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )} */}
      <div className="max-w-1160 mx-auto">
        <Swiper
          navigation={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Navigation]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {cars
            ?.filter(function (item) {
              return item?.type === selectedCategory;
            })
            .map((car, index) => {
              return (
                <SwiperSlide>
                  <Card car={car} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

export default SliderCars;
